import React, { useEffect, useState } from "react";
import { RarityButton, rarity } from "./contex/Helper";

const Rarity = () => {
  const [activeRarity, setActiveRarity] = React.useState("Background");
  const [filterRarity, setFilterRarity] = useState(rarity);
  const [size, setSize] = useState(6);
  const filterHandler = (value) => {
    setFilterRarity(() => {
      return rarity.filter((obj) => obj.type == value);
    });
    setSize(6);
  };
  const rarityFilterHandler = (value) => {
    setActiveRarity(value);
    filterHandler(value);
  };

  useEffect(() => {
    filterHandler(activeRarity);
  }, []);
  const exploreMoreHandler = () => {
    if (filterRarity.length > size) {
      setSize(size + 6);
    } else {
      setSize(size - 6);
    }
  };
  return (
    <div id="rarity">
      <div className="container pt-lg-0 text-white">
        <h2
          data-aos="fade-up"
          data-aos-duration="1500"
          className="heading text-center text-white py-lg-5 py-4"
        >
          Rarity
        </h2>
        <div className="d-flex justify-content-between text-nowrap overflow-auto">
          {RarityButton.map((obj, index) => (
            <button
              key={index}
              onClick={() => rarityFilterHandler(obj.title)}
              className={`font-md d-inline-block ms-2 rounded-pill rarity-btn  mb-5 mx-3 ${
                obj.title === activeRarity ? "rarity-btn-active" : ""
              }`}
            >
              {obj.title}
            </button>
          ))}
        </div>
        <div className="row ff-poppins justify-content-center">
          {filterRarity.slice(0, size).map((obj, index) => (
            <div
              key={index}
              data-aos="fade-up"
              data-aos-duration="1500"
              className="col-lg-4 animated-box col-md-6 col-12 mb-3 pt-4"
            >
              <div className="rarity-card ">
                <img height="350px" className="w-100" src={obj.imgUrl} alt="" />

                <div className="mt-4">
                  <span className="font-sm d-inline-block ms-2">
                    {obj.title}
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="ps-2 font-lg">
                    <span className="font-lg"> Rarity </span>
                    <span>
                      {obj.text} <span className="text-white">:</span>
                      <span className="text-gradient fw-bold">
                        <br />
                        {obj.rarity}
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    {/* <button className=" fw-bold common-btn text-nowrap">
                      Buy Now
                    </button> */}

                    <a
                      target="_blank"
                      href="https://openzoo.io/collection/0xc2b3af0a56387d4ef095a80a174f493e9a0438a5"
                      className="common-btn fw-bold text-nowrap"
                    >
                      Buy now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="text-center mt-5 pt-lg-5">
            <button onClick={exploreMoreHandler} className="common-btn ">
              {filterRarity.length > size ? "Explore More" : "Explore Less"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rarity;
