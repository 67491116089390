import React from "react";
import logo from "./assets/img/logo.png";
import {
  Discord,
  InstaGram,
  Medium,
  OPenSea,
  Telegram,
  Twitter,
} from "./contex/Icon";
import img1 from "./assets/img/footer-img-1.png";
import img2 from "./assets/img/footer-img-2.png";

const Footer = () => {
  return (
    <>
      <div
        data-aos="zoom-in-down"
        data-aos-duration="1500"
        className="position-relative mt-5"
      >
        <img className="footer-img-1" src={img1} alt="" />
        <img className="footer-img-2" src={img2} alt="" />
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <a href="#">
                <img src={logo} alt="" />
              </a>
              <div className="d-flex justify-content-center py-sm-4 py-3 mb-4">
                <div className="mx-1">
                  <a target="_blank" href="https://twitter.com/SyndicateSmall">
                    <Twitter />
                  </a>
                </div>
                <div className="mx-1">
                  <a
                    target="_blank"
                    href="https://discord.com/invite/QPhyNt6TkH"
                  >
                    <Discord />
                  </a>
                </div>
                <div className="mx-1">
                  <a target="_blank" href=" https://t.me/+fX63RhawM1w4Y2Yx">
                    <Telegram />
                  </a>
                </div>
                <div className="mx-1">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/smallarmssyndicate/"
                  >
                    <InstaGram />
                  </a>
                </div>
                <div className="mx-1">
                  <a
                    target="_blank"
                    href=" https://smallarmssyndicate.medium.com/"
                  >
                    <Medium />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
