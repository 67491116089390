// import img from "../assets/img/rarity-img-1.png";
// import img2 from "../assets/img/rarity-img-2.png";
// import img3 from "../assets/img/rarity-img-3.png";
// import img4 from "../assets/img/rarity-img-4.png";
// import img5 from "../assets/img/rarity-img-5.png";
// import img6 from "../assets/img/rarity-img-6.png";

//=========================================== BACKGROUND-RARITY===========================================

import lightGreen from "../assets/img/Background/Light Green.png";
import Battlefield from "../assets/img/Background/Battlefield.png";
import Black from "../assets/img/Background/Black-blackground.png";
import Bloody from "../assets/img/Background/Bloody-background.png";
import Cream from "../assets/img/Background/Cream-background.png";
import Fiery from "../assets/img/Background/Fiery-background.png";
import Forest from "../assets/img/Background/Forest.png";
import Galaxy from "../assets/img/Background/Galaxy .png";
import GoldenBrick from "../assets/img/Background/Golden Brick Wall.png";
import lightBlue from "../assets/img/Background/Light Blue.png";
import Orange from "../assets/img/Background/Orange.png";
import Pink from "../assets/img/Background/Pink.png";
import Silver from "../assets/img/Background/Silver.png";
import Yellow from "../assets/img/Background/Yellow.png";
import Beach from "../assets/img/Background/Beach.png";
import CitySkyline from "../assets/img/Background/City-Skyline.png";
import Desert from "../assets/img/Background/Desert.png";
import MexicanDayoftheDeadTheme from "../assets/img/Background/MexicanDayoftheDeadTheme.png";

//=========================================== ACCESSORIES-RARITY===========================================
import CellPhone from "../assets/img/Accessories/Cell Phone.png";
import Cigarette from "../assets/img/Accessories/Cigarette.png";
import Cup from "../assets/img/Accessories/Cup.png";
import DiamondRing from "../assets/img/Accessories/Diamond Ring.png";
import Earrings from "../assets/img/Accessories/Earrings.png";
import FaceScar from "../assets/img/Accessories/Face Scar.png";
import FaceTattoo from "../assets/img/Accessories/Face Tattoo.png";
import GunHolster from "../assets/img/Accessories/Gun Holster.png";
import Gun from "../assets/img/Accessories/Gun.png";
import HandGun from "../assets/img/Accessories/Hand Gun.png";
import HandsOnFire from "../assets/img/Accessories/Hands On Fire.png";
import KnittedFingerlessGloves from "../assets/img/Accessories/Knitted Fingerless Gloves.png";
import Money from "../assets/img/Accessories/Money.png";
import NoseRing from "../assets/img/Accessories/Nose Ring.png";
import SmolderingBaldEagle from "../assets/img/Accessories/Smoldering Bald Eagle.png";
import Sunglasses from "../assets/img/Accessories/Sunglasses.png";
import Sword from "../assets/img/Accessories/Sword.png";
import TattooAnchor from "../assets/img/Accessories/Tattoo Anchor.png";
import TattooDiamond from "../assets/img/Accessories/Tattoo Diamond.png";
import TattooEye from "../assets/img/Accessories/Tattoo Eye.png";
import TattooSpider from "../assets/img/Accessories/Tattoo Spider.png";
import ToothPick from "../assets/img/Accessories/ToothPick.png";
import TurkeyLeg from "../assets/img/Accessories/Turkey Leg.png";
import ZooRenaGloves from "../assets/img/Accessories/ZooRena Gloves.png";
import BatThrowingKnives from "../assets/img/Accessories/Bat Throwing Knives.png";
import BattleAxe from "../assets/img/Accessories/Battle Axe.png";
import Blaster from "../assets/img/Accessories/Blaster.png";
import BlueLightSaber from "../assets/img/Accessories/Blue LightSaber.png";
import BoneAxe from "../assets/img/Accessories/Bone Axe.png";
import CACBud from "../assets/img/Accessories/CAC Bud.png";
import CACSlapper from "../assets/img/Accessories/CAC Slapper.png";
import ChameleonPet from "../assets/img/Accessories/Chameleon Pet.png";
import DevilTrident from "../assets/img/Accessories/Devil Trident.png";
import GoldChain from "../assets/img/Accessories/Gold Chain.png";
import GreenLightSaber from "../assets/img/Accessories/Green LightSaber.png";
import JokerCards from "../assets/img/Accessories/Joker Cards.png";
import MultiTattoo from "../assets/img/Accessories/Multi Tattoo.png";
import PurpleLightSaber from "../assets/img/Accessories/Purple LightSaber.png";
import RedLightSaber from "../assets/img/Accessories/Red LightSaber.png";

//=========================================== CLOTHES-RARITY===========================================

import AngelOutfit from "../assets/img/Clothes/Angel Outfit.png";
import Army from "../assets/img/Clothes/Army.png";
import Bathing from "../assets/img/Clothes/Bathing.png";
import BikerVest from "../assets/img/Clothes/Biker Vest.png";
import Braveheart from "../assets/img/Clothes/Braveheart.png";
import ColonialDress from "../assets/img/Clothes/Colonial Dress.png";
import Dress from "../assets/img/Clothes/Dress.png";
import KnightArmor from "../assets/img/Clothes/Knight Armor.png";
import LongCoat from "../assets/img/Clothes/Long Coat.png";
import PoppedCollar from "../assets/img/Clothes/Popped Collar.png";
import RoyalCape from "../assets/img/Clothes/Royal Cape.png";
import StormTrooper from "../assets/img/Clothes/Storm Trooper.png";
import TShirt from "../assets/img/Clothes/T Shirt.png";
import TornDress from "../assets/img/Clothes/Torn Dress.png";
import TurtleNeck from "../assets/img/Clothes/Turtle Neck.png";
import Tuxedo from "../assets/img/Clothes/Tuxedo.png";
import VikingDress from "../assets/img/Clothes/Viking Dress.png";
import Wandalorian from "../assets/img/Clothes/Wandalorian.png";
import ZookeeperDress from "../assets/img/Clothes/Zookeeper Dress.png";
import BatSuit from "../assets/img/Clothes/Bat Suit.png";
import BoneArmor from "../assets/img/Clothes/Bone Armor.png";
import CACArmor from "../assets/img/Clothes/CAC Armor.png";
import DarthVaderSuit from "../assets/img/Clothes/Darth Vader Suit.png";
import DevilTux from "../assets/img/Clothes/Devil Tux.png";
import JediRobe from "../assets/img/Clothes/Jedi Robe.png";
import JokerSuit from "../assets/img/Clothes/Joker Suit.png";

//=========================================== EYES-RARITY===========================================

import Ball from "../assets/img/Eyes/8 Ball.png";
import Angry from "../assets/img/Eyes/Angry.png";
import Cyborg from "../assets/img/Eyes/Cyborg.png";
import Empty from "../assets/img/Eyes/Empty.png";
import EyePatch from "../assets/img/Eyes/Eye Patch.png";
import FireEyes from "../assets/img/Eyes/Fire Eyes.png";
import Glasses from "../assets/img/Eyes/Glasses.png";
import Heart from "../assets/img/Eyes/Heart.png";
import Laser from "../assets/img/Eyes/Laser.png";
import MoneyEyes from "../assets/img/Eyes/Money Eyes.png";
import Normal from "../assets/img/Eyes/Normal.png";
import OneBlack from "../assets/img/Eyes/One Black.png";
import PoppingOutEyes from "../assets/img/Eyes/Popping Out Eyes.png";
import RedEyes from "../assets/img/Eyes/Red Eyes.png";
import Snake from "../assets/img/Eyes/Snake.png";
import Wink from "../assets/img/Eyes/Wink.png";
import XEyes from "../assets/img/Eyes/X Eyes.png";
import CACEyes from "../assets/img/Eyes/CAC Eyes.png";
import SadEyes from "../assets/img/Eyes/Sad Eyes.png";
import WCEyes from "../assets/img/Eyes/WC Eyes.png";

//=========================================== HEAD-RARITY===========================================

import BackwardsSnapback from "../assets/img/Head/Backwards Snapback.png";
import BaldingHead from "../assets/img/Head/Balding Head.png";
import BigCrown from "../assets/img/Head/Big Crown.png";
import ColonialWig from "../assets/img/Head/Colonial Wig.png";
import FireCrown from "../assets/img/Head/Fire Crown.png";
import Halo from "../assets/img/Head/Halo.png";
import Headset from "../assets/img/Head/Headset.png";
import KingsCrown from "../assets/img/Head/Kings Crown.png";
import KnightHelmet from "../assets/img/Head/Knight Helmet.png";
import LongBlonde from "../assets/img/Head/Long Blonde.png";
import LongHair from "../assets/img/Head/Long Hair.png";
import Manbun from "../assets/img/Head/Manbun.png";
import Mullet from "../assets/img/Head/Mullet.png";
import Mohawk from "../assets/img/Head/Mohawk.png";
import ShortHair from "../assets/img/Head/Short Hair.png";
import StormTrooper2 from "../assets/img/Head/Storm Trooper.png";
import VikingBraid from "../assets/img/Head/Viking Braid.png";
import VikingHelmet from "../assets/img/Head/Viking Helmet.png";
import Visor from "../assets/img/Head/Visor.png";
import WandalorianHelmet from "../assets/img/Head/Wandalorian Helmet.png";
import ZookeeperHat from "../assets/img/Head/Zookeeper Hat.png";
import Beanie from "../assets/img/Head/Beanie.png";
import Cowboy from "../assets/img/Head/Cowboy.png";
import Fedora from "../assets/img/Head/Fedora.png";
import BatHead from "../assets/img/Head/Bat Head.png";
import BoneHelmet from "../assets/img/Head/Bone Helmet.png";
import CACHead from "../assets/img/Head/CAC Head.png";
import CACHelmet from "../assets/img/Head/CAC Helmet.png";
import ChameleonHead from "../assets/img/Head/Chameleon Head.png";
import DarthVaderHelmet from "../assets/img/Head/Darth Vader Helmet.png";
import DevilHorns from "../assets/img/Head/Devil Horns.png";
import JediHair from "../assets/img/Head/Jedi Hair.png";
import JokerHair from "../assets/img/Head/Joker Hair.png";

//=========================================== MOUTHS-RARITY===========================================

import BitingLip from "../assets/img/Mouths/Biting Lip.png";
import Bloody2 from "../assets/img/Mouths/Bloody.png";
import Braces from "../assets/img/Mouths/Braces.png";
import Dead from "../assets/img/Mouths/Dead.png";
import Drooling from "../assets/img/Mouths/Drooling.png";
import FoodInMouth from "../assets/img/Mouths/Food In Mouth.png";
import GoldGrill from "../assets/img/Mouths/Gold Grill.png";
import Happy from "../assets/img/Mouths/Happy.png";
import Kissy from "../assets/img/Mouths/Kissy.png";
import KnifeInMouth from "../assets/img/Mouths/Knife In Mouth.png";
import MissingTeeth from "../assets/img/Mouths/Missing Teeth.png";
import Normal2 from "../assets/img/Mouths/Normal.png";
import Open from "../assets/img/Mouths/Open.png";
import Smolder from "../assets/img/Mouths/Smolder.png";
import TieDye from "../assets/img/Mouths/Tie Dye.png";
import Tongue from "../assets/img/Mouths/Tongue.png";
import BuckTeeth from "../assets/img/Mouths/BuckTeeth.png";
import Growl from "../assets/img/Mouths/Growl.png";
import Vampire from "../assets/img/Mouths/Vampire.png";

//=========================================== SKINS-RARITY===========================================

import BattleWounds from "../assets/img/Skins/Battle Wounds.png";
import Blue from "../assets/img/Skins/Blue.png";
import Braveheart2 from "../assets/img/Skins/Braveheart.png";
import Cheetah from "../assets/img/Skins/Cheetah.png";
import Extinct from "../assets/img/Skins/Extinct.png";
import FurySkin from "../assets/img/Skins/Fury Skin.png";
import Gold from "../assets/img/Skins/Gold.png";
import Gray from "../assets/img/Skins/Gray.png";
import Green from "../assets/img/Skins/Green.png";
import Maroon from "../assets/img/Skins/Maroon.png";
import Pink2 from "../assets/img/Skins/Pink.png";
import PinkGradient from "../assets/img/Skins/Pink Gradient.png";
import Robot from "../assets/img/Skins/Robot.png";
import TieDye2 from "../assets/img/Skins/Tie Dye.png";
import Yellow2 from "../assets/img/Skins/Yellow.png";
import Zombie from "../assets/img/Skins/Zombie.png";
import Jacked from "../assets/img/Skins/Jacked.png";
import Jelly from "../assets/img/Skins/Jelly.png";
import Joker from "../assets/img/Skins/Joker.png";
import Muscle from "../assets/img/Skins/Muscle.png";

export const RarityButton = [
  {
    id: 8,
    title: "Background",
  },
  {
    id: 9,
    title: "Accessories",
  },
  {
    id: 10,
    title: "Clothes",
  },
  {
    id: 11,
    title: "Mouths",
  },
  {
    id: 12,
    title: "Skins",
  },
  {
    id: 14,
    title: "Head",
  },
  {
    id: 13,
    title: "Eyes",
  },
];

export const rarity = [
  //================================ BACKGROUND RARITES =========================================//
  {
    type: "Background",
    title: "Background (Light Green)",
    rarity: "11.88%",
    imgUrl: lightGreen,
  },
  {
    type: "Background",
    title: "Background (Battlefield)",
    rarity: "3.02%",
    imgUrl: Battlefield,
  },
  {
    type: "Background",
    title: "Background (Black)",
    rarity: "0.1%",
    imgUrl: Black,
  },
  {
    type: "Background",
    title: "Background (Bloody)",
    rarity: "3.03%",
    imgUrl: Bloody,
  },
  {
    type: "Background",
    title: "Background (Cream)",
    rarity: "11.91%",
    imgUrl: Cream,
  },
  {
    type: "Background",
    title: "Background (Fiery)",
    rarity: "2.93%",
    imgUrl: Fiery,
  },
  {
    type: "Background",
    title: "Background (Forest)",
    rarity: "3.05%",
    imgUrl: Forest,
  },
  {
    type: "Background",
    title: "Background (Galaxy)",
    rarity: "3.00%",
    imgUrl: Galaxy,
  },
  {
    type: "Background",
    title: "Background (Golden Brick)",
    rarity: "3.00%",
    imgUrl: GoldenBrick,
  },
  {
    type: "Background",
    title: "Background (light Blue)",
    rarity: "11.92%",
    imgUrl: lightBlue,
  },
  {
    type: "Background",
    title: "Background (Orange)",
    rarity: "12.14%",
    imgUrl: Orange,
  },
  {
    type: "Background",
    title: "Background (Pink)",
    rarity: "12.07%",
    imgUrl: Pink,
  },
  {
    type: "Background",
    title: "Background (Silver)",
    rarity: "11.04%",
    imgUrl: Silver,
  },
  {
    type: "Background",
    title: "Background (Yellow)",
    rarity: "10.91%",
    imgUrl: Yellow,
  },
  {
    type: "Background",
    title: "Background (Beach)",
    rarity: " .5%",
    imgUrl: Beach,
  },
  {
    type: "Background",
    title: "Background (City Skyline)",
    rarity: "1%",
    imgUrl: CitySkyline,
  },
  {
    type: "Background",
    title: "Background (Mexican Day of the Dead)",
    rarity: "1%",
    imgUrl: MexicanDayoftheDeadTheme,
  },
  {
    type: "Background",
    title: "Background (Desert)",
    rarity: "1%",
    imgUrl: Desert,
  },
  //================================ ACCESSORIES RARITES =========================================//
  {
    type: "Accessories",
    title: "Accessories (Cell Phone)",
    rarity: "5.20%",
    imgUrl: CellPhone,
  },
  {
    type: "Accessories",
    title: "Accessories (Cigarette)",
    rarity: "0.84%",
    imgUrl: Cigarette,
  },
  {
    type: "Accessories",
    title: "Accessories (Cup)",
    rarity: "5.19%",
    imgUrl: Cup,
  },
  {
    type: "Accessories",
    title: "Accessories (Diamond Ring)",
    rarity: "5.18% ",
    imgUrl: DiamondRing,
  },
  {
    type: "Accessories",
    title: "Accessories (Earrings)",
    rarity: "1.04% ",
    imgUrl: Earrings,
  },
  {
    type: "Accessories",
    title: "Accessories (Face Scar)",
    rarity: "0.81%",
    imgUrl: FaceScar,
  },
  {
    type: "Accessories",
    title: "Accessories (Face Tattoo)",
    rarity: "0.76%",
    imgUrl: FaceTattoo,
  },
  {
    type: "Accessories",
    title: "Accessories (Gun Holster)",
    rarity: "1.04%",
    imgUrl: GunHolster,
  },
  {
    type: "Accessories",
    title: "Accessories (Gun)",
    rarity: "4.15%",
    imgUrl: Gun,
  },
  {
    type: "Accessories",
    title: "Accessories (Hand Gun)",
    rarity: "4.16%",
    imgUrl: HandGun,
  },
  {
    type: "Accessories",
    title: "Accessories (Hands On Fire)",
    rarity: "4.15%",
    imgUrl: HandsOnFire,
  },
  {
    type: "Accessories",
    title: "Accessories (Knitted Fingerless Gloves)",
    rarity: "4.16%",
    imgUrl: KnittedFingerlessGloves,
  },
  {
    type: "Accessories",
    title: "Accessories (Money)",
    rarity: "5.16%",
    imgUrl: Money,
  },
  {
    type: "Accessories",
    title: "Accessories (Nose Ring)",
    rarity: "5.19% ",
    imgUrl: NoseRing,
  },
  {
    type: "Accessories",
    title: "Accessories (Smoldering Bald Eagle)",
    rarity: " 1.04% ",
    imgUrl: SmolderingBaldEagle,
  },
  {
    type: "Accessories",
    title: "Accessories (Sunglasses)",
    rarity: "5.17%",
    imgUrl: Sunglasses,
  },
  {
    type: "Accessories",
    title: "Accessories (Sword)",
    rarity: "1.04%",
    imgUrl: Sword,
  },
  {
    type: "Accessories",
    title: "Accessories (Tattoo Anchor)",
    rarity: "0.70%",
    imgUrl: TattooAnchor,
  },
  {
    type: "Accessories",
    title: "Accessories (Tattoo Diamond)",
    rarity: "0.73%",
    imgUrl: TattooDiamond,
  },
  {
    type: "Accessories",
    title: "Accessories (Tattoo Eye)",
    rarity: " 0.64%",
    imgUrl: TattooEye,
  },
  {
    type: "Accessories",
    title: "Accessories (Tattoo Spider)",
    rarity: "0.64% ",
    imgUrl: TattooSpider,
  },
  {
    type: "Accessories",
    title: "Accessories (Tooth Pick)",
    rarity: "3.52% ",
    imgUrl: ToothPick,
  },
  {
    type: "Accessories",
    title: "Accessories (Turkey Leg)",
    rarity: "6.23%",
    imgUrl: TurkeyLeg,
  },
  {
    type: "Accessories",
    title: "Accessories (Zoo Rena Gloves)",
    rarity: "0.56%",
    imgUrl: ZooRenaGloves,
  },
  {
    type: "Accessories",
    title: "Accessories (CAC Slapper)",
    rarity: ".25%",
    imgUrl: CACSlapper,
  },
  {
    type: "Accessories",
    title: "Accessories (Battle Axe)",
    rarity: ".68%",
    imgUrl: BattleAxe,
  },
  {
    type: "Accessories",
    title: "Accessories (Bone Axe)",
    rarity: ".55%",
    imgUrl: BoneAxe,
  },
  {
    type: "Accessories",
    title: "Accessories (Blaster)",
    rarity: ".65%",
    imgUrl: Blaster,
  },
  {
    type: "Accessories",
    title: "Accessories (Bat Throwing Knives)",
    rarity: ".65%",
    imgUrl: BatThrowingKnives,
  },
  {
    type: "Accessories",
    title: "Accessories (Purple LightSaber)",
    rarity: ".3%",
    imgUrl: PurpleLightSaber,
  },
  {
    type: "Accessories",
    title: "Accessories (Blue LightSaber)",
    rarity: ".3%",
    imgUrl: BlueLightSaber,
  },
  {
    type: "Accessories",
    title: "Accessories (Red LightSaber)",
    rarity: ".3%",
    imgUrl: RedLightSaber,
  },
  {
    type: "Accessories",
    title: "Accessories (Green LightSaber)",
    rarity: ".3%",
    imgUrl: GreenLightSaber,
  },
  {
    type: "Accessories",
    title: "Accessories (Devil Trident)",
    rarity: ".65%",
    imgUrl: DevilTrident,
  },
  {
    type: "Accessories",
    title: "Accessories (Chameleon Pet)",
    rarity: ".65%",
    imgUrl: ChameleonPet,
  },
  {
    type: "Accessories",
    title: "Accessories (Multi Tattoo)",
    rarity: ".35%",
    imgUrl: MultiTattoo,
  },
  {
    type: "Accessories",
    title: "Accessories (Joker Cards)",
    rarity: ".29%",
    imgUrl: JokerCards,
  },
  {
    type: "Accessories",
    title: "Accessories (CAC Bud)",
    rarity: ".51%",
    imgUrl: CACBud,
  },
  {
    type: "Accessories",
    title: "Accessories (Gold Chain)",
    rarity: "1%",
    imgUrl: GoldChain,
  },

  //================================ CLOTHES RARITES =========================================//
  {
    type: "Clothes",
    title: "Clothes (Angel Outfit)",
    rarity: " 1.02%",
    imgUrl: AngelOutfit,
  },
  {
    type: "Clothes",
    title: "Clothes (Army)",
    rarity: "6.95%",
    imgUrl: Army,
  },
  {
    type: "Clothes",
    title: "Clothes (Bathing)",
    rarity: "6.86%",
    imgUrl: Bathing,
  },
  {
    type: "Clothes",
    title: "Clothes (Biker Vest)",
    rarity: "7.06%",
    imgUrl: BikerVest,
  },
  {
    type: "Clothes",
    title: "Clothes (Braveheart)",
    rarity: "5.99%",
    imgUrl: Braveheart,
  },
  {
    type: "Clothes",
    title: "Clothes (Colonial Dress)",
    rarity: "6.06%",
    imgUrl: ColonialDress,
  },
  {
    type: "Clothes",
    title: "Clothes (Dress)",
    rarity: "6.04%",
    imgUrl: Dress,
  },
  {
    type: "Clothes",
    title: "Clothes (Knight Armor)",
    rarity: "1.02%",
    imgUrl: KnightArmor,
  },
  {
    type: "Clothes",
    title: "Clothes (Long Coat)",
    rarity: "6.97%",
    imgUrl: LongCoat,
  },
  {
    type: "Clothes",
    title: "Clothes (Popped Collar)",
    rarity: "6.96%",
    imgUrl: PoppedCollar,
  },
  {
    type: "Clothes",
    title: "Clothes (Royal Cape)",
    rarity: " 1.03%",
    imgUrl: RoyalCape,
  },
  {
    type: "Clothes",
    title: "Clothes (Storm Trooper)",
    rarity: "6.96%",
    imgUrl: StormTrooper,
  },
  {
    type: "Clothes",
    title: "Clothes (T Shirt)",
    rarity: "7%",
    imgUrl: TShirt,
  },
  {
    type: "Clothes",
    title: "Clothes (Torn Dress)",
    rarity: "6.98%",
    imgUrl: TornDress,
  },
  {
    type: "Clothes",
    title: "Clothes (Turtle Neck)",
    rarity: "7%",
    imgUrl: TurtleNeck,
  },
  {
    type: "Clothes",
    title: "Clothes (Tuxedo)",
    rarity: "1.01%",
    imgUrl: Tuxedo,
  },
  {
    type: "Clothes",
    title: "Clothes (Viking Dress)",
    rarity: "1.01%",
    imgUrl: VikingDress,
  },
  {
    type: "Clothes",
    title: "Clothes (Wandalorian)",
    rarity: "7.02%",
    imgUrl: Wandalorian,
  },
  {
    type: "Clothes",
    title: "Clothes (Zookeeper Dress)",
    rarity: "7.06%",
    imgUrl: ZookeeperDress,
  },
  {
    type: "Clothes",
    title: "Clothes (CAC Armor)",
    rarity: ".25%",
    imgUrl: CACArmor,
  },
  {
    type: "Clothes",
    title: "Clothes (Bone Armor)",
    rarity: ".49%",
    imgUrl: BoneArmor,
  },
  {
    type: "Clothes",
    title: "Clothes (Darth Vader Suit)",
    rarity: ".44%",
    imgUrl: DarthVaderSuit,
  },
  {
    type: "Clothes",
    title: "Clothes (Bat Suit)",
    rarity: ".30%",
    imgUrl: BatSuit,
  },
  {
    type: "Clothes",
    title: "Clothes (Jedi Robe)",
    rarity: ".44%",
    imgUrl: JediRobe,
  },
  {
    type: "Clothes",
    title: "Clothes (Devil Tux)",
    rarity: ".65%",
    imgUrl: DevilTux,
  },
  {
    type: "Clothes",
    title: "Clothes (Joker Suit)",
    rarity: ".17%",
    imgUrl: JokerSuit,
  },
  //================================  EYES RARITES =========================================//
  {
    type: "Eyes",
    title: "Eyes ( 8 Ball)",
    rarity: "9.02%",
    imgUrl: Ball,
  },
  {
    type: "Eyes",
    title: "Eyes (Angry)",
    rarity: "7.99%",
    imgUrl: Angry,
  },
  {
    type: "Eyes",
    title: "Eyes (Cyborg)",
    rarity: "8.04%",
    imgUrl: Cyborg,
  },
  {
    type: "Eyes",
    title: "Eyes (Empty)",
    rarity: ".1%",
    imgUrl: Empty,
  },
  {
    type: "Eyes",
    title: "Eyes (Eye Patch)",
    rarity: "7.95%",
    imgUrl: EyePatch,
  },
  {
    type: "Eyes",
    title: "Eyes (Fire Eyes)",
    rarity: "2%",
    imgUrl: FireEyes,
  },
  {
    type: "Eyes",
    title: "Eyes (Glasses)",
    rarity: "2.06%",
    imgUrl: Glasses,
  },
  {
    type: "Eyes",
    title: "Eyes (Heart)",
    rarity: "2.01%",
    imgUrl: Heart,
  },
  {
    type: "Eyes",
    title: "Eyes (Laser)",
    rarity: "2.02%",
    imgUrl: Laser,
  },
  {
    type: "Eyes",
    title: "Eyes (Money Eyes)",
    rarity: "8.98%",
    imgUrl: MoneyEyes,
  },
  {
    type: "Eyes",
    title: "Eyes (Normal)",
    rarity: "7.94%",
    imgUrl: Normal,
  },
  {
    type: "Eyes",
    title: "Eyes (One Black)",
    rarity: "7.97%",
    imgUrl: OneBlack,
  },
  {
    type: "Eyes",
    title: "Eyes (Popping Out Eyes)",
    rarity: "2%",
    imgUrl: PoppingOutEyes,
  },
  {
    type: "Eyes",
    title: "Eyes (Red Eyes)",
    rarity: "8.09%",
    imgUrl: RedEyes,
  },
  {
    type: "Eyes",
    title: "Eyes (Snake)",
    rarity: "8%",
    imgUrl: Snake,
  },
  {
    type: "Eyes",
    title: "Eyes (Wink)",
    rarity: "7.99%",
    imgUrl: Wink,
  },
  {
    type: "Eyes",
    title: "Eyes (X Eyes)",
    rarity: "7.94%",
    imgUrl: XEyes,
  },
  {
    type: "Eyes",
    title: "Eyes (Sad Eyes)",
    rarity: ".75%",
    imgUrl: SadEyes,
  },
  {
    type: "Eyes",
    title: "Eyes (WC Eyes)",
    rarity: ".98%",
    imgUrl: WCEyes,
  },
  {
    type: "Eyes",
    title: "Eyes (CAC Eyes)",
    rarity: "1%",
    imgUrl: CACEyes,
  },
  //================================  HEAD RARITES =========================================//
  {
    type: "Head",
    title: "Head ( Backwards Snapback)",
    rarity: "2.73%",
    imgUrl: BackwardsSnapback,
  },
  {
    type: "Head",
    title: "Head (Balding Head)",
    rarity: "3.12%",
    imgUrl: BaldingHead,
  },
  {
    type: "Head",
    title: "Head (Big Crown)",
    rarity: "1.04%",
    imgUrl: BigCrown,
  },
  {
    type: "Head",
    title: "Head (Colonial Wig)",
    rarity: "0.96%",
    imgUrl: ColonialWig,
  },
  {
    type: "Head",
    title: "Head (Fire Crown)",
    rarity: "1.02%",
    imgUrl: FireCrown,
  },
  {
    type: "Head",
    title: "Head (Halo)",
    rarity: "3.12%",
    imgUrl: Halo,
  },
  {
    type: "Head",
    title: "Head (Headset)",
    rarity: "2.75%",
    imgUrl: Headset,
  },
  {
    type: "Head",
    title: "Head (Kings Crown)",
    rarity: "0.94%",
    imgUrl: KingsCrown,
  },
  {
    type: "Head",
    title: "Head (Knight Helmet)",
    rarity: " 0.92%",
    imgUrl: KnightHelmet,
  },
  {
    type: "Head",
    title: "Head (Long Blonde)",
    rarity: "2.73%",
    imgUrl: LongBlonde,
  },
  {
    type: "Head",
    title: "Head (Long Hair)",
    rarity: "2.93%",
    imgUrl: LongHair,
  },
  {
    type: "Head",
    title: "Head (Manbun)",
    rarity: "3.12%",
    imgUrl: Manbun,
  },
  {
    type: "Head",
    title: "Head (Mullet)",
    rarity: "0.94%",
    imgUrl: Mullet,
  },
  {
    type: "Head",
    title: "Head (Mohawk)",
    rarity: "3.12%",
    imgUrl: Mohawk,
  },
  {
    type: "Head",
    title: "Head (Short Hair)",
    rarity: "1.04%",
    imgUrl: ShortHair,
  },
  {
    type: "Head",
    title: "Head (Storm Trooper)",
    rarity: "0.39%",
    imgUrl: StormTrooper2,
  },
  {
    type: "Head",
    title: "Head (Viking Braid)",
    rarity: "2.84%",
    imgUrl: VikingBraid,
  },
  {
    type: "Head",
    title: "Head (Viking Helmet)",
    rarity: "0.92%",
    imgUrl: VikingHelmet,
  },
  {
    type: "Head",
    title: "Head (Visor)",
    rarity: "3.12%",
    imgUrl: Visor,
  },
  {
    type: "Head",
    title: "Head (Wandalorian Helmet)",
    rarity: "0.41%",
    imgUrl: WandalorianHelmet,
  },
  {
    type: "Head",
    title: "Head (Zookeeper Hat)",
    rarity: "0.94%",
    imgUrl: ZookeeperHat,
  },
  // Head: Fedora > 2.82%
  {
    type: "Head",
    title: "Head (Fedora)",
    rarity: "2.82%",
    imgUrl: Fedora,
  },
  {
    type: "Head",
    title: "Head (Cowboy)",
    rarity: "2.94%",
    imgUrl: Cowboy,
  },
  {
    type: "Head",
    title: "Head (Beanie)",
    rarity: "2.8%",
    imgUrl: Beanie,
  },
  {
    type: "Head",
    title: "Head (CAC Helmet)",
    rarity: ".25%",
    imgUrl: CACHelmet,
  },
  {
    type: "Head",
    title: "Head (CAC Head)",
    rarity: ".5%",
    imgUrl: CACHead,
  },
  {
    type: "Head",
    title: "Head (Bone Helmet)",
    rarity: ".65%",
    imgUrl: BoneHelmet,
  },
  {
    type: "Head",
    title: "Head (Darth Vader Helmet)",
    rarity: ".44%",
    imgUrl: DarthVaderHelmet,
  },
  {
    type: "Head",
    title: "Head (Bat Head)",
    rarity: ".3%",
    imgUrl: BatHead,
  },
  {
    type: "Head",
    title: "Head (Jedi Hair)",
    rarity: ".43%",
    imgUrl: JediHair,
  },
  {
    type: "Head",
    title: "Head (Devil Horns)",
    rarity: ".64%",
    imgUrl: DevilHorns,
  },
  {
    type: "Head",
    title: "Head (Chameleon Head)",
    rarity: ".65%",
    imgUrl: ChameleonHead,
  },
  {
    type: "Head",
    title: "Head (Joker Hair)",
    rarity: ".17%",
    imgUrl: JokerHair,
  },
  //================================  SKINS RARITES =========================================//
  {
    type: "Skins",
    title: "Skins (Battle Wounds)",
    rarity: "6.22%",
    imgUrl: BattleWounds,
  },
  {
    type: "Skins",
    title: "Skins (Blue)",
    rarity: " 6.24%",
    imgUrl: Blue,
  },
  {
    type: "Skins",
    title: "Skins (Braveheart)",
    rarity: "6.22%",
    imgUrl: Braveheart2,
  },
  {
    type: "Skins",
    title: "Skins (Cheetah)",
    rarity: "6.21%",
    imgUrl: Cheetah,
  },
  {
    type: "Skins",
    title: "Skins (Extinct)",
    rarity: " .1%",
    imgUrl: Extinct,
  },
  {
    type: "Skins",
    title: "Skins (Fury Skin)",
    rarity: "6.23%",
    imgUrl: FurySkin,
  },
  {
    type: "Skins",
    title: "Skins (Gold)",
    rarity: "6.94%",
    imgUrl: Gold,
  },
  {
    type: "Head",
    title: "Head (Gray)",
    rarity: "6.94%",
    imgUrl: Gray,
  },
  {
    type: "Skins",
    title: "Skins (Green)",
    rarity: "6.94%",
    imgUrl: Green,
  },
  {
    type: "Skins",
    title: "Skins (Maroon)",
    rarity: "6.94%",
    imgUrl: Maroon,
  },
  {
    type: "Skins",
    title: "Skins (Pink)",
    rarity: "6.84%",
    imgUrl: Pink2,
  },
  {
    type: "Skins",
    title: "Skins (Pink Gradient)",
    rarity: "6.94%",
    imgUrl: PinkGradient,
  },
  {
    type: "Skins",
    title: "Skins (Robot)",
    rarity: "6.94%",
    imgUrl: Robot,
  },
  {
    type: "Skins",
    title: "Skins (Tie Dye)",
    rarity: "6.94%",
    imgUrl: TieDye2,
  },
  {
    type: "Skins",
    title: "Skins (Yellow)",
    rarity: "6.68%",
    imgUrl: Yellow2,
  },
  {
    type: "Skins",
    title: "Skins (Zombie)",
    rarity: "6.68%",
    imgUrl: Zombie,
  },
  {
    type: "Skins",
    title: "Skins (Jelly)",
    rarity: ".84%",
    imgUrl: Jelly,
  },
  {
    type: "Skins",
    title: "Skins (Muscle)",
    rarity: ".65%",
    imgUrl: Muscle,
  },
  {
    type: "Skins",
    title: "Skins (Jacked)",
    rarity: "1%",
    imgUrl: Jacked,
  },
  {
    type: "Skins",
    title: "Skins (Joker)",
    rarity: ".17%",
    imgUrl: Joker,
  },
  //================================  MOUTHS RARITES =========================================//
  {
    type: "Mouths",
    title: "Mouths ( Biting Lip)",
    rarity: "4.46%",
    imgUrl: BitingLip,
  },
  {
    type: "Mouths",
    title: "Mouths (Bloody)",
    rarity: "4.22%",
    imgUrl: Bloody2,
  },
  {
    type: "Mouths",
    title: "Mouths (Braces)",
    rarity: "4.46%",
    imgUrl: Braces,
  },
  {
    type: "Mouths",
    title: "Mouths (Dead)",
    rarity: ".1%",
    imgUrl: Dead,
  },
  {
    type: "Mouths",
    title: "Mouths (Drooling)",
    rarity: "4.5%",
    imgUrl: Drooling,
  },
  {
    type: "Mouths",
    title: "Mouths (Food In Mouth)",
    rarity: "4.51%",
    imgUrl: FoodInMouth,
  },
  {
    type: "Mouths",
    title: "Mouths (Gold Grill)",
    rarity: "4.43%",
    imgUrl: GoldGrill,
  },
  {
    type: "Head",
    title: "Head (Happy)",
    rarity: "4.51%",
    imgUrl: Happy,
  },
  {
    type: "Mouths",
    title: "Mouths (Kissy)",
    rarity: " 4.47%",
    imgUrl: Kissy,
  },
  {
    type: "Mouths",
    title: "Mouths (Knife In Mouth)",
    rarity: "4.45%",
    imgUrl: KnifeInMouth,
  },
  {
    type: "Mouths",
    title: "Mouths (Missing Teeth)",
    rarity: "4.47%",
    imgUrl: MissingTeeth,
  },
  {
    type: "Mouths",
    title: "Mouths (Normal)",
    rarity: "37.31%",
    imgUrl: Normal2,
  },
  {
    type: "Mouths",
    title: "Mouths (Open)",
    rarity: " 4.42%",
    imgUrl: Open,
  },
  {
    type: "Mouths",
    title: "Mouths (Smolder)",
    rarity: "4.44%",
    imgUrl: Smolder,
  },
  {
    type: "Mouths",
    title: "Mouths (Tie Dye)",
    rarity: "4.44%",
    imgUrl: TieDye,
  },
  {
    type: "Mouths",
    title: "Mouths (Tongue)",
    rarity: "4.5%",
    imgUrl: Tongue,
  },
  {
    type: "Mouths",
    title: "Mouths (Vampire)",
    rarity: "1%",
    imgUrl: Vampire,
  },
  {
    type: "Mouths",
    title: "Mouths (Buck Teeth)",
    rarity: ".99%",
    imgUrl: BuckTeeth,
  },
  {
    type: "Mouths",
    title: "Mouths (Growl)",
    rarity: ".3%",
    imgUrl: Growl,
  },
];
