import React from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

const GameModal = ({ show, handleClose }) => {
  return (
    <>
      <Modal
        className="video_modal"
        show={show}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Header
          className="border-0 w-100 h-100 p-2"
          closeButton
        ></Modal.Header>
        <Modal.Body className="px-2 py-0">
          <ReactPlayer
            className="w-100 h-100"
            url="https://www.youtube.com/watch?v=7SmONCaxQkk"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GameModal;
