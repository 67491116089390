import React from "react";
import team0 from "./assets/img/team-img-1.png";
import team1 from "./assets/img/team-new-img-1.png";
import team2 from "./assets/img/team-new-img-2.png";
import { Twitter } from "./contex/Icon";

const Team = () => {
  return (
    <>
      <div className="team" id="team">
        <div className="container py-5 my-sm-5">
          <h2 className="heading text-center text-white my-sm-5">Team</h2>
          <div className="row justify-content-center pb-2  pt-lg-5 pt-3">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="col-lg-4 col-11 mt-4"
            >
              <div className="position-relative h-100 team-card">
                <img className="w-100 h-100" src={team0} alt="Jgb" />
                <div className="team-card-text">
                  <h2 className="small-heading text-center">MetaMax</h2>
                  <p className="para-common text-center mb-0">(Advisors)</p>
                  <div className="text-center pb-2">
                    <a target="_blank" href="https://twitter.com/MetaMax24">
                      <Twitter />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              className="col-lg-4 col-11 mt-4"
            >
              <div className="position-relative h-100 team-card">
                <img className="w-100 h-100" src={team1} alt="Jgb" />
                <div className="team-card-text">
                  <h2 className="small-heading text-center">Jgb</h2>
                  <p className="para-common text-center mb-0">(Advisors)</p>
                  <div className="text-center pb-2">
                    <a target="_blank" href="https://twitter.com/jgbtrade666">
                      <Twitter />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="col-lg-4 col-11 mt-4"
            >
              <div className="position-relative h-100 team-card">
                <img className="w-100 h-100" src={team2} alt="Imredryan" />
                <div className="team-card-text ">
                  <h2 className="small-heading text-center">ImRedRyan</h2>
                  <p className="para-common text-center mb-0">(Advisors)</p>
                  <div className="text-center pb-2">
                    <a
                      target="_blank"
                      href=" https://twitter.com/CryptoRedryan"
                    >
                      <Twitter />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
