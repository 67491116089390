import React, { useEffect } from "react";
import Footer from "./Footer";
import Game from "./Game";
import Header from "./Header";
import { Hero } from "./Hero";
import PreLoader from "./PreLoader";
import Rarity from "./Rarity";
import Royality from "./Royality";
import Team from "./Team";
import Welcome from "./Welcome";
import TheRoadmap from "./TheRoadmap";
import BackToTop from "./common/BackToTop";

const Home = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  });
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          <div className="hero-header-wrapper d-flex flex-column">
            <Header />
            <Hero />
          </div>
          <Welcome />
          <TheRoadmap />
          <Team />
          <Rarity />
          <Royality />
          <Game />
          <Footer />
          <BackToTop />
        </>
      )}
    </>
  );
};

export default Home;
