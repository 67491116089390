import React, { useEffect } from "react";
import img1 from "./assets/img/hero-img-1.png";
import img2 from "./assets/img/hero-img-2.png";
import img3 from "./assets/img/hero-img-3.png";
import img4 from "./assets/img/hero-img-4.png";
import { useLayoutEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

export const Hero = () => {
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:1200px)": function () {
        let herocards = gsap.timeline({});
        herocards.from(".hero_cards_parent", {
          yPercent: -10,
          duration: 1,
          stagger: 0.2,
        });
      },
    });

    ScrollTrigger.matchMedia({
      "(min-width:1200px) and (max-width:1299.98px)": function () {
        let second = gsap.timeline({
          scrollTrigger: {
            trigger: ".hero-section",
            start: "10% top",
            end: "center top",
            scrub: 2,
          },
        });
        second.fromTo(
          ".hero_img",
          {
            yPercent: 10,
          },
          {
            yPercent: 0,
          }
        );
      },
    });

    ScrollTrigger.matchMedia({
      "(min-width:1300px) and (max-width:1399.98px)": function () {
        let second = gsap.timeline({
          scrollTrigger: {
            trigger: ".hero-section",
            start: "10% top",
            end: "center top",
            scrub: 2,
          },
        });
        second.fromTo(
          ".hero_img",
          {
            yPercent: 5,
          },
          {
            yPercent: -5,
          }
        );
      },
    });

    ScrollTrigger.matchMedia({
      "(min-width:1400px) and (max-width:1599.98px)": function () {
        let second = gsap.timeline({
          scrollTrigger: {
            trigger: ".hero-section",
            start: "10% top",
            end: "center top",
            scrub: 2,
          },
        });
        second.fromTo(
          ".hero_img",
          {
            yPercent: -10,
          },
          {
            yPercent: -20,
          }
        );
      },
    });

    ScrollTrigger.matchMedia({
      "(min-width:1600px)": function () {
        let second = gsap.timeline({
          scrollTrigger: {
            trigger: ".hero-section",
            start: "10% top",
            end: "center top",
            scrub: 3,
          },
        });
        second.fromTo(
          ".hero_img",
          {
            yPercent: 15,
          },
          {
            yPercent: 0,
          }
        );
      },
    });
  }, []);

  return (
    <div className="hero-section flex-grow-1 d-flex flex-column overflow-hidden">
      <div className="container d-flex flex-column flex-grow-1 pt-5 mt-5 ">
        <div
          className="text-center flex-grow-1 d-flex flex-column pt-5 justify-content-center align-items-center text-white mb-5 pb-5 hero-text"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <h2 className="main-heading">Small Arms Syndicate</h2>
          <p className="hero-para mb-lg-5 px-sm-5 mx-lg-5">
            Small Arms Syndicate is a collection of 10,000 unique T-rex Small
            Arms NFTs— unique digital collectibles minted on the Wanchain
            blockchain. Your Small Arms NFT is your ticket to members-only perks
            and is your utility token in the S.A.S ecosystem.
          </p>
        </div>
        <div className="row parent_animation">
          <div className="col-md-3 col-6 d-flex flex-column justify-content-end hero_cards_parent">
            <img className="w-100 hero_img" src={img1} alt="img1" />
          </div>
          <div className="col-md-3 col-6 d-flex flex-column justify-content-end hero_cards_parent">
            <img className="w-100 hero_img" src={img2} alt="img2" />
          </div>
          <div className="col-3 d-none d-md-flex flex-column justify-content-end hero_cards_parent">
            <img className="w-100 hero_img" src={img3} alt="img3" />
          </div>
          <div className="col-3 d-none d-md-flex flex-column justify-content-end hero_cards_parent">
            <img className="w-100 hero_img" src={img4} alt="img4" />
          </div>
        </div>
      </div>
    </div>
  );
};
