import React from "react";
import welcome from "./assets/img/welcome-img.png";

const Welcome = () => {
  return (
    <div className="pt-5 mt-lg-4" id="buy">
      <div className="container py-5">
        <div className="row justify-content-lg-between justify-content-center py-sm-5">
          <div
            className="col-lg-5 col-sm-10 col-12"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <img className="w-100 px-5 px-sm-0" src={welcome} alt="" />
          </div>
          <div
            className="col-lg-6 col-12 d-flex flex-column justify-content-center mt-4 pt-sm-4 mt-lg-0 pt-lg-0"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <h2 className="heading ">
              <span className="font-xxl text-white">
                Welcome to
                <br className="d-none d-md-block d-lg-none d-xl-block" />
              </span>
              <span className="text-gradient"> Small Arms Syndicate</span>
            </h2>
            <p className="para-common text-white mt-3">
              Each one of our SAS NFTs was randomly generated. We have over 180
              different assets each with different rarity you can see in our
              rarity section. You can buy a Small Arms Syndicate NFT now
              exclusively on&nbsp;
              <a
                className="openZooLink"
                target="_blank"
                href="http://openzoo.io/"
              >
                OpenZoo.io.
              </a>
              &nbsp;Each SAS NFT owns a piece of the Small Arms Syndicate
              Ecosystem.
            </p>
            <div className="mt-4 pt-xl-4">
              <a
                target="_blank"
                href="https://openzoo.io/collection/0xc2b3af0a56387d4ef095a80a174f493e9a0438a5"
                className="common-btn"
              >
                Buy now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
