import React from "react";
import { useScreenFixedProvider } from "./contex/useScreenFixedProvider";
import { useMediaQuery } from "react-responsive";
import { headerData } from "./contex/data";
import logo from "./assets/img/logo.png";

const Header = () => {
  const { showOverlay, setShowOverlay } = useScreenFixedProvider();

  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 991.98 });
    return isBeforeDesktop ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  return (
    <>
      <section className="d-flex w-100 navbar-wrapper justify-content-between  bg-black">
        <div className="container">
          <Desktop>
            <div
              className="d-flex w-100 align-items-center justify-content-between list-unstyled"
              data-aos="flip-down"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              <li>
                <a href="#">
                  <img className="nav-logo" src={logo} alt="logo" />
                </a>
              </li>
              <div className="d-flex align-items-center">
                <ul className="p-0 m-0">
                  {headerData.map((item, index) => (
                    <li
                      key={index}
                      className={`mx-2 nav-list-items px-xxl-1 d-inline-block ${
                        index == 0 ? "active" : ""
                      }`}
                    >
                      <a href={`#${item.url}`}>{item.title}</a>
                    </li>
                  ))}
                  <span className="ms-1">
                    <a
                      target="_blank"
                      href="https://rexdex.finance"
                      className="common-btn ms-2"
                    >
                      REXDEX
                    </a>
                  </span>
                </ul>
              </div>
            </div>
          </Desktop>

          {/* OVERLAY HERE  THIS CODE RUN BEFORE 992PX*/}
          <BeforeDesktop>
            <div
              className={`${showOverlay ? "overlay-active" : ""} overlay-nav`}
            >
              <div className="nav-bg-light h-screen d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column text-center ">
                  <ul className="p-0">
                    <li className=" transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2  d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="#buy"
                          className="nav-link  overlay-text  text-white text-decoration-none"
                        >
                          BUY A SAS
                        </a>
                      </span>
                    </li>
                    <li className=" transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2  d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="#roadmap"
                          className="nav-link  overlay-text  text-white text-decoration-none"
                        >
                          RoadMap
                        </a>
                      </span>
                    </li>
                    <li className="nav-item   transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2  d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="#team"
                          className="nav-link  overlay-text  text-white text-decoration-none"
                        >
                          team
                        </a>
                      </span>
                    </li>
                    <li className="nav-item   transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="#rarity"
                          className="nav-link  overlay-text  text-white text-decoration-none"
                        >
                          Rarity
                        </a>
                      </span>
                    </li>
                    <li className="nav-item   transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="#royality"
                          className="nav-link  overlay-text text-white text-decoration-none"
                        >
                          Stash
                        </a>
                      </span>
                    </li>
                    <li className="nav-item   transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="#game"
                          className="nav-link  overlay-text text-white text-decoration-none"
                        >
                          Syndicate Game
                        </a>
                      </span>
                    </li>
                    <li className="nav-item transition-0_3sEase overflow-hidden my-4">
                      <span
                        className="py-2 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          target="_blank"
                          href="https://rexdex.finance"
                          className="common-btn"
                        >
                          REXDEX
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <nav className="navbar h-86 navbar-expand-lg navbar-light  py-lg-3 w-100">
              <div className="d-flex justify-content-between w-100 align-items-center list-unstyled">
                <a href="#">
                  <img className="nav-logo" src={logo} alt="logo" />
                </a>
                <div
                  className={`${
                    showOverlay ? "animate" : ""
                  } position-relative navbarwrapper  hamburger-icon d-flex flex-column d-lg-none`}
                  onClick={() => setShowOverlay(!showOverlay)}
                >
                  <span className="first d-inline-block"></span>
                  <span className="second d-inline-block"></span>
                  <span className="third d-inline-block"></span>
                </div>
              </div>
            </nav>
          </BeforeDesktop>
        </div>
      </section>
    </>
  );
};

export default Header;
