import React, { useEffect } from "react";
import img1 from "./assets/img/royality-img-1.png";
import img2 from "./assets/img/royality-img-2.png";
import img3 from "./assets/img/royality-img-3.png";
import img4 from "./assets/img/royality-img-4.png";
import img5 from "./assets/img/royality-img-6.png";

const Royality = () => {
  const [webSocketValue, setWebSocketValue] = React.useState("");
  const [stashedAmmount, setStashedAmmount] = React.useState(0);
  const socket = new WebSocket(
    "wss://api.wanchain.org:8443/ws/v3/c502cb64008bc8dbdd6eef8d89c7505e9ba41c77900a714cc19a6d6a20d71abb"
  );
  useEffect(() => {
    socket.addEventListener("open", function () {
      socket.send(
        JSON.stringify({
          jsonrpc: "2.0",
          method: "getTokenBalance",
          params: {
            address: "0x26cbd0617c90a3D9601A243E461A8B481C1De5b6",
            tokenScAddr: "0x6e11655d6aB3781C6613db8CB1Bc3deE9a7e111F",
            timestamp: "####",
            signature: "######",
          },
          id: 1,
        })
      );
    });

    socket.addEventListener("message", function (event) {
      setWebSocketValue(event.data);
    });
  }, []);

  useEffect(() => {
    if (!!webSocketValue) {
      const objectValue = JSON.parse(webSocketValue);
      const value = objectValue.result;
      //value divided by 10 raised to the power of 18
      const ammount = value / 10 ** 18;
      setStashedAmmount(ammount.toFixed(2));
    }
  }, [webSocketValue]);

  return (
    <div className="royality py-5 my-lg-5" id="royality">
      <div className="container py-sm-5 my-4">
        <h2
          data-aos="fade-up"
          data-aos-duration="1500"
          className="heading text-center text-white mb-4 mb-sm-5"
        >
          The Stash
        </h2>
        <div
          data-aos="zoom-in"
          data-aos-duration="1500"
          className="row justify-content-center pb-lg-4"
        >
          <div className="col-10">
            <div className="royality-card text-center">
              <img className="royality-box-1" src={img4} alt="" />
              <img className="royality-box-2" src={img5} alt="" />
              <p className="para-common text-white font-25">
                20% of everything Small Arms Syndicate makes gets stashed in our
                Stash wallet you can see on&nbsp;
                <a
                  className="openZooLink"
                  target="_blank"
                  href="https://wanscan.org/"
                >
                  wanscan.org
                </a>
                . Once this amount reaches 100k $ZOO it gets sent back to SAS
                NFT holders. 20% of the stash will be exclusively sent to
                Extinct Skin holders and the other 80% is evenly distributed to
                all SAS NFT holders. The More NFTs you have the more you get!
              </p>
              <div className="py-4 mb-2">
                <h2 className="text-gradient font-3xl">
                  Stashed Amount : {stashedAmmount}
                </h2>
                {/* ADD LINK WHICH REDIRECT TO
                "https://www.wanscan.org/address/0x26cbd0617c90a3D9601A243E461A8B481C1De5b6" THIS LINK */}
                <div className="pt-3">
                  <a
                    target="_blank"
                    href="https://www.wanscan.org/address/0x26cbd0617c90a3D9601A243E461A8B481C1De5b6"
                    className="btn-royality ff-poppins"
                  >
                    View Stash Wallet
                  </a>
                </div>
              </div>
              <div className="row justify-content-center mx-0 pt-4">
                <div className="col-3 d-flex flex-column justify-content-end">
                  <img className="w-100" src={img1} alt="" />
                </div>
                <div className="col-3 d-flex flex-column justify-content-end">
                  <img className="w-100" src={img2} alt="" />
                </div>
                <div className="col-3 d-flex flex-column justify-content-end">
                  <img className="w-100" src={img3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Royality;
