import React from "react";
import Logo from "./assets/img/preloaderLogo.png";
const PreLoader = () => {
  return (
    <div className="preLoader">
      <div className="logo">
        <img src={Logo} className="preloader-logo" alt="" />
      </div>
    </div>
  );
};

export default PreLoader;
