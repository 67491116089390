import "./App.css";
import ScreenFixedProvider from "./components/contex/useScreenFixedProvider";
import Home from "./components/Home";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init({ once: true });
  return (
    <div>
      <ScreenFixedProvider>
        <Home />
      </ScreenFixedProvider>
    </div>
  );
}

export default App;
