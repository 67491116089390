import React, { useState } from "react";
import videoIcon from "./assets/img/video-icon.png";
import videoImg from "./assets/img/game-section-video-main-img.png";
import GameModal from "./contex/GameModal";
import pdf from "./assets/pdf/game-narrative-bible01.pdf";

const Game = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="py-5 my-5" id="game">
        <div className="container mb-5">
          <div className="row text-white justify-content-center">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="col-12 col-lg-10 col-xl-7 pt-4 pt-md-5 pt-xl-0 d-flex flex-column justify-content-center order-2 order-xl-1 pe-xl-5 game-content"
            >
              <h2 className="heading">Small Arms Syndicate Game</h2>
              <h3 className="fs-lg fw-normal mt-2 py-2">
                Third person Campaign Shooter
              </h3>
              <p className="para-common">
                There will be an undecided number of factions all fighting over
                separate pieces of land. Each land generates a passive income to
                that faction via the syndicate token. Factions can wage battles
                on lands to capture the land and steal the income for their
                faction.
              </p>

              <p className="para-common">
                Each faction will have a king and or council. The council or
                King will be elected via governance from the syndicate token,
                but they must hold a Small Arms syndicate NFT to be eligible.
                The king or council will receive extra income from the faction
                as well as decide who is banished from the faction. If a player
                is banished they will be sent to the dead lands. This is the
                land that generates no income but the players there can create a
                rebellion and try to take an entire faction. Or try and get
                recruited to another faction.
              </p>

              <p className="para-common">
                Each faction will have a main kingdom. With a marketplace for
                buying equipment upgrades and other upgrades.
              </p>
              <div className="mt-3">
                <a href={pdf} className="common-btn" download>
                  Game Narrative Bible
                </a>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="col-lg-6 col-xl-5 col-md-10 col-12 order-1 order-xl-2 mb-5 mb-lg-0  d-flex align-items-center"
            >
              <img
                className="w-100 position-relative px-2 sm:px-3 px-sm-0"
                src={videoImg}
                alt="videoimg"
              />
              <img
                className="video-icon01 position-absolute cursor-pointer"
                onClick={handleShow}
                src={videoIcon}
                alt="videoimg"
              />
              <GameModal show={show} handleClose={handleClose} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Game;
