import React from "react";
import { useState, useEffect } from "react";
import { BackToTopIcon } from "../contex/Icon";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  useEffect(() => {
    window.addEventListener("scroll", function () {
      setPageYOffset(window.pageYOffset);
    });
  }, []);
  useEffect(() => {
    if (pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [pageYOffset]);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <div onClick={() => scrollToTop()}>
          <BackToTopIcon />
        </div>
      )}
    </div>
  );
};

export default BackToTop;
