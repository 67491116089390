import React, { useState } from "react";
import roadmapBgImg from "../components/assets/img/roadmap-bg-book-image.png";
import phaseImg from "../components/assets/img/phase-img.png";
import phaseImg4 from "../components/assets/img/phase-4-img-with-grass.png";
import phaseImg6 from "../components/assets/img/phase-6-img.png";
import phaseSideImg from "../components/assets/img/roadmap-side-img-2.png";

const TheRoadmap = () => {
  const [isPhase1, setIsPhase1] = useState(false);
  const [isPhase2, setIsPhase2] = useState(false);
  const [isPhase3, setIsPhase3] = useState(false);
  const [isPhase4, setIsPhase4] = useState(false);
  const [isPhase5, setIsPhase5] = useState(false);
  const [isPhase6, setIsPhase6] = useState(false);

  return (
    <>
      <div className="position-relative">
        <img
          className="position-absolute phase-side-img-3 d-none d-sm-block"
          src={phaseSideImg}
          alt="phaseSideImg"
        />
        <img
          className="position-absolute phase-side-img"
          src={phaseSideImg}
          alt="phaseSideImg"
        />
        <img
          className="position-absolute phase-side-img-2 d-none d-lg-block"
          src={phaseSideImg}
          alt="phaseSideImg"
        />

        <div className="container py-5" id="roadmap">
          <div className="row justify-content-center align-items-center mt-xl-5 pt-xl-2">
            <div
              className="col-12 col-md-11 col-lg-10"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <h2 className="heading text-white text-center my-2">RoadMap</h2>
              <p className="para-common text-white text-center mb-5 px-xl-5">
                Small Arms Syndicates aims to not just be a fun NFT collecting
                experience, but an entire ecosystem that runs through Our SAS
                NFTs and our new adopted cryptocurrency $REX. We want to develop
                multiple use cases and utility for both our NFTs and our Token.
                The Small Arms Syndicate ecosystem will be released in Phases.
                You can see our current vision listed below.
              </p>
            </div>
            <div className="col-12 position-relative d-none d-sm-block roadmap_parent">
              <img
                className="w-100 h-100 roadmap-img"
                src={roadmapBgImg}
                alt="roadmapBgImg"
              />
              {/* PHASE 1 */}
              <div className="position-absolute roadmap_phase_img_1">
                <img
                  className="w-100 h-100 phase_img_1"
                  src={phaseImg}
                  alt="phaseImg1"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase1(true)}
                  onMouseLeave={() => setIsPhase1(false)}
                >
                  Phase 1
                </h3>
                {isPhase1 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 1
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Mint our entire NFT collection of 10000
                      <br />
                      unique NFTs on openzoo.io.✅
                    </p>
                  </div>
                )}
              </div>
              {/* PHASE 2 */}
              <div className="position-absolute roadmap_phase_img_2">
                <img
                  className="w-100 h-100 phase_img_1"
                  src={phaseImg}
                  alt="phaseImg2"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase2(true)}
                  onMouseLeave={() => setIsPhase2(false)}
                >
                  Phase 2
                </h3>
                {isPhase2 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 2
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Develop and release a community
                      <br />
                      created AMM-DEX called REXDEX.
                    </p>
                  </div>
                )}
              </div>
              {/* PHASE 3 */}
              <div className="position-absolute roadmap_phase_img_3">
                <img
                  className="w-100 h-100 phase_img_1"
                  src={phaseImg}
                  alt="phaseImg3"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase3(true)}
                  onMouseLeave={() => setIsPhase3(false)}
                >
                  Phase 3
                </h3>
                {isPhase3 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 3
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Acquire REXDEX partnerships.
                    </p>
                  </div>
                )}
              </div>
              {/* PHASE 4 */}
              <div className="position-absolute roadmap_phase_img_4">
                <img
                  className="w-100 h-100 phase_img_1"
                  src={phaseImg4}
                  alt="phaseImg4"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase4(true)}
                  onMouseLeave={() => setIsPhase4(false)}
                >
                  Phase 4
                </h3>
                {isPhase4 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 4
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Multi-chain launch of REXDEX
                    </p>
                  </div>
                )}
              </div>
              {/* PHASE 5 */}
              <div className="position-absolute roadmap_phase_img_5">
                <img
                  className="w-100 h-100 phase_img_1"
                  src={phaseImg}
                  alt="phaseImg5"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase5(true)}
                  onMouseLeave={() => setIsPhase5(false)}
                >
                  Phase 5
                </h3>
                {isPhase5 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 5
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Develop a NFT self serve staking Platform. Where users can
                      stake their SAS NFTs for daily rewards via our adopted
                      $REX Token. Plus, other NFT collections can come and
                      create their own staking rewards for their own collections
                      with a click of a few buttons via our Platform.
                    </p>
                  </div>
                )}
              </div>
              {/* PHASE 6 */}
              <div className="position-absolute roadmap_phase_img_6">
                <img
                  className="w-100 h-100 phase_img_1"
                  src={phaseImg6}
                  alt="phaseImg6"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase6(true)}
                  onMouseLeave={() => setIsPhase6(false)}
                >
                  Phase 6
                </h3>
                {isPhase6 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 6
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Develop the Small Arms Syndicate
                      <br />
                      Play to earn game.
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* PHASE 1 */}
            <div className="col-6 d-sm-none">
              <div className="position-relative text-center d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100 mobile_phase_img_1"
                  src={phaseImg6}
                  alt="phaseImg1"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase1(true)}
                  onMouseLeave={() => setIsPhase1(false)}
                >
                  Phase 1
                </h3>
                {isPhase1 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 1
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Mint our entire NFT collection of 10000 unique NFTs on
                      openzoo.io.✅
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* PHASE 2 */}
            <div className="col-6 d-sm-none">
              <div className="position-relative text-center d-flex align-items-center justify-content-center mobile-phase-right-side">
                <img
                  className="w-100 h-100 mobile_phase_img_1"
                  src={phaseImg6}
                  alt="phaseImg2"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase2(true)}
                  onMouseLeave={() => setIsPhase2(false)}
                >
                  Phase 2
                </h3>
                {isPhase2 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 2
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Develop and release a community
                      <br />
                      created AMM-DEX called REXDEX.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* PHASE 3 */}
            <div className="col-6 d-sm-none mt-4">
              <div className="position-relative text-center d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100 mobile_phase_img_1"
                  src={phaseImg6}
                  alt="phaseImg3"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase3(true)}
                  onMouseLeave={() => setIsPhase3(false)}
                >
                  Phase 3
                </h3>
                {isPhase3 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 3
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      {/* A community created lending platform. */}
                      Acquire REXDEX partnerships.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* PHASE 4 */}
            <div className="col-6 d-sm-none mt-4">
              <div className="position-relative text-center d-flex align-items-center justify-content-center mobile-phase-right-side">
                <img
                  className="w-100 h-100 mobile_phase_img_1"
                  src={phaseImg6}
                  alt="phaseImg4"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase4(true)}
                  onMouseLeave={() => setIsPhase4(false)}
                >
                  Phase 4
                </h3>
                {isPhase4 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 4
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Multi-chain launch of REXDEX
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* PHASE 5 */}
            <div className="col-6 d-sm-none mt-4">
              <div className="position-relative text-center d-flex align-items-center justify-content-center mobile_roadmap_phase_img_5">
                <img
                  className="w-100 h-100 mobile_phase_img_1"
                  src={phaseImg6}
                  alt="phaseImg5"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase5(true)}
                  onMouseLeave={() => setIsPhase5(false)}
                >
                  Phase 5
                </h3>
                {isPhase5 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 5
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Develop a NFT self serve staking Platform. Where users can
                      stake their SAS NFTs for daily rewards via our adopted
                      $REX Token. Plus, other NFT collections can come and
                      create their own staking rewards for their own collections
                      with a click of a few buttons via our Platform.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* PHASE 6 */}
            <div className="col-6 d-sm-none mt-4">
              <div className="position-relative text-center d-flex align-items-center justify-content-center mobile-phase-right-side">
                <img
                  className="w-100 h-100 mobile_phase_img_1"
                  src={phaseImg6}
                  alt="phaseImg6"
                />
                <h3
                  className="position-absolute phase_heading fw-normal text-white mb-0 cursor-pointer"
                  onMouseOver={() => setIsPhase6(true)}
                  onMouseLeave={() => setIsPhase6(false)}
                >
                  Phase 6
                </h3>
                {isPhase6 && (
                  <div className="roadmap_card d-flex flex-column align-items-center justify-content-center text-center phase_1_content">
                    <h2 className="font-xl fw-normal text-gradient mb-2">
                      Phase 6
                    </h2>
                    <p className="font-sm fw-normal text-white ff-poppins mb-0">
                      Develop the Small Arms Syndicate
                      <br />
                      Play to earn game.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheRoadmap;
